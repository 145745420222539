@import url(../stylesheet/solid.css);
@import url(../stylesheet/regular.css);
@import url(../stylesheet/brands.css);
@font-face {
  font-family: "PTSans Regular";
  src: url("../fonts/PTSans/PTSansRegular/PTSansRegular.ttf") format("truetype");
}

@font-face {
  font-family: "PTSans Bold";
  src: url("../fonts/PTSans/PTSansBold/PTSansBold.ttf") format("truetype");
}

@font-face {
  font-family: "PTSans Italic";
  src: url("../fonts/PTSans/PTSansItalic/PTSansItalic.ttf") format("truetype");
}

/* top */
#top {
  display: block;
  min-height: 48px;
}

#top .top-container {
  display: block;
}

#top .top-container #top-left-links,
#top .top-container #top-right-links {
  vertical-align: middle;
}

/* left */
#top #top-left-links ul li a,
#top #top-left-links ul li a:visited {
  height: 48px;
  line-height: 50px;
  border: transparent;
}

#top #top-left-links ul li a:hover {
  border-bottom: none;
}

/* language */
#top .language-currency {
  vertical-align: middle;
  margin-top: 5px;
}

#top .btn-link.btn-language i:first-child,
#top .btn-link.btn-currency i:first-child {
  top: 0;
  font-size: 14px;
}

#top .btn-language > span {
  margin-right: 4px;
}

#top-right-links .language-currency .dropdown-menu {
  margin-top: 3px;
}

#top .btn.btn-link.dropdown-toggle.btn-language {
  margin-bottom: 3px;
}

#top .btn.btn-link.dropdown-toggle.btn-language span {
  font-size: 14px;
}

/* work time and register */
#top .list-inline {
  vertical-align: middle;
}

#top .list-inline a span {
  font-size: 14px;
}

#top .dropdown-menu {
  margin: 0;
}

#top #top-right-links > ul > li,
#top #top-right-links > ul > li:hover {
  border-bottom: none;
}

#top #top-right-links > ul > li a {
  height: 48px !important;
  line-height: 48px !important;
}

/* header */
header {
  padding: 22px 0 23px;
}

/* logo */
#logo {
  margin: 0;
}

/* search */
#search {
  margin: 0 0 6px;
}

#search .btn-lg {
  padding: 6px;
  color: #252525;
}

#search .input-lg {
  height: 32px;
}

#search .input-lg::placeholder {
  color: #252525;
}

#search .btn-search {
  padding: 6px 6px 6px 12px;
}

#search .fa-search {
  color: #252525;
  margin: 0 4px 0 0;
}

/* phones */
.phones-top-box {
  margin-bottom: 0;
}

.phones-top-box > div {
  margin: 0 -25px 20px 0;
  text-align: center;
}

.phones-top-box .phones-dropdown .dropdown-menu {
  padding: 10px 10px 10px 30px;
}

.phones-top-box .phones-dropdown > a > span {
  font-size: 18px;
}

.phones-top-box .phones-dropdown > a:hover {
  color: #0067a7;
}

.phones-top-box .phones-dropdown i.fa-caret-down {
  margin-left: 8px;
  color: #252525;
}

.phones-top-box .phones-dropdown a.show-phones {
  left: 5px;
  color: #252525;
}

/* cart */
.buttons-top-box {
  line-height: 39px;
  margin-bottom: -2px;
}

.buttons-top-box #cart {
  display: block;
  margin: 0 34px;
}

.buttons-top-box div a:hover i span {
  background: #252525;
}

.buttons-top-box div i {
  font-size: 18px;
}

.buttons-top-box div i span {
  background: #252525;
  font-family: "PTSans Regular", sans-serif;
}

#menu .dropdown-inner .see-all {
  color: #0067a7 !important;
}

#menu .nav > li > a {
  font-size: 14px;
  font-weight: 400;
}

#menu .dropdown-inner a {
  font-size: 14px;
}

footer {
  padding-top: 0;
}

footer .footer-top {
  background: #252525;
}

footer .footer-top .first-row {
  padding: 20px 0;
}

footer .footer-top .first-row .h5 {
  color: #ffffff;
  text-transform: initial;
  font-family: "PTSans Bold", sans-serif;
}

footer .footer-top .first-row .input-box input {
  background: #ffffff;
  color: #252525;
  font-size: 14px;
}

footer .footer-top .first-row .input-box input::placeholder {
  color: #252525;
  font-size: 14px;
}

footer .footer-top .first-row .actions button {
  font-size: 14px;
}

footer .footer-top .first-row .socials-box a {
  color: #ffffff !important;
}

footer .footer-bottom {
  padding: 35px 0;
  background: #ffffff;
}

footer .footer-bottom .third-row .h5 {
  color: #252525;
  text-transform: initial;
  font-family: "PTSans Bold", sans-serif;
}

footer .footer-bottom .third-row ul li {
  font-size: 16px;
  color: #252525 !important;
}

footer .footer-bottom .third-row a {
  font-size: 16px;
}

footer .footer-bottom .third-row a:hover {
  text-decoration: none;
}

footer .footer-copyright {
  padding: 0;
  margin: 0;
}

footer .footer-copyright .last-row {
  margin-top: 0;
}

footer .footer-copyright .last-row .oct-copy {
  color: #252525;
}

footer .footer-copyright .last-row .payment-box > span {
  opacity: 1;
}

footer .footer-copyright .last-row .payment-box .text-payments {
  color: #252525;
}

footer a {
  color: #252525 !important;
}

footer a:visited {
  color: #252525 !important;
}

footer a:hover {
  color: #252525 !important;
}

footer .footer-contacts ul li i {
  font-size: 12px;
}

.common-home .owl-carousel {
  z-index: 0;
}

.common-home .owl-carousel .owl-buttons div i {
  font-size: 24px;
}

.common-home .oct-slideshow-box {
  padding: 0;
  margin: 0 !important;
}

.common-home .oct-slideshow-box .owl-wrapper-outer {
  padding: 0;
}

.common-home .oct-slideshow-box .item {
  padding: 0;
}

.common-home .oct-slideshow-box .owl-controls .owl-page span {
  border-color: #ffffff;
  background: transparent;
}

.common-home .oct-slideshow-box .owl-controls .owl-page.active span {
  border-color: #0067a7 !important;
}

.common-home .oct-product-tab {
  padding: 0;
  border: none;
  box-shadow: none;
}

.common-home .oct-product-tab .owl-buttons div i {
  font-size: 20px;
}

.common-home .oct-product-tab ul.nav-tabs > li a {
  color: #252525 !important;
  text-transform: initial !important;
}

.common-home .oct-product-tab ul.nav-tabs > li:hover {
  background: #ececec !important;
}

.common-home .oct-product-tab ul.nav-tabs > li:hover a {
  background: #ececec !important;
}

.common-home .oct-product-tab ul.nav-tabs > li.active {
  background: #ececec !important;
}

.common-home .oct-product-tab ul.nav-tabs > li.active a {
  background: #ececec !important;
}

.common-home .oct-product-tab .item {
  padding: 0 0 0 30px;
  text-align: left;
}

.common-home .oct-product-tab .name {
  padding: 0 0 11px;
  margin: 0 30px 15px 0;
  border-bottom: 1px solid #ececec;
}

.common-home .oct-product-tab .owl-carousel .owl-item .name a {
  margin: 0;
  color: #0067a7;
  font-size: 14px;
}

.common-home .oct-product-tab .owl-carousel .owl-item .name a:hover {
  text-decoration: none;
}

.common-home .oct-product-tab .isbn {
  margin: 12px 0 5px;
  color: #252525;
}

.common-home .oct-product-tab .name {
  border-bottom: 1px solid #ececec;
}

.common-home .oct-product-tab .owl-carousel .owl-item .price {
  line-height: 38px;
  margin-bottom: 20px;
  font-family: "PTSans Regular", sans-serif;
  font-size: 16px;
}

.common-home .oct-product-tab .oct-product-stock {
  margin-top: 6px;
  margin-bottom: 0;
}

.common-home .oct-product-tab .owl-item .oct-product-stock span {
  color: #252525 !important;
}

.common-home .oct-product-tab .owl-carousel .owl-buttons div {
  height: 44px;
  color: #0067a7 !important;
}

.common-home .oct-product-tab .owl-carousel .owl-item .image .quick-view {
  text-align: center;
}

.common-home .main-advantage-row {
  padding: 0 15px;
  margin: 15px 0 0;
  box-shadow: none;
  border: 1px solid #ececec;
  background: #ffffff;
}

.common-home .main-advantage-item {
  height: 100px;
  padding-left: 20px;
  padding-right: 0;
  box-shadow: none;
  text-align: left;
}

.common-home .main-advantage-item .main-advantage-item-icon i {
  font-size: 21px;
  color: #252525;
}

.common-home .main-advantage-item .main-advantage-item-text {
  color: #252525;
  font-size: 18px;
  line-height: 22px;
}

.common-home .oct-carousel-header {
  color: #252525 !important;
  text-transform: initial;
}

.common-home .oct-carousel-header:after {
  display: none;
}

.common-home .news-row .oct-carousel-box {
  box-shadow: none !important;
}

.common-home .news-row .owl-carousel .owl-item .name a {
  margin-bottom: 4px;
  color: #252525 !important;
}

.common-home .news-row .owl-carousel .owl-item .name a:hover {
  text-decoration: none;
}

.common-home .news-row .owl-carousel .owl-item .read-more {
  margin: 6px 0 0;
}

.common-home .news-row .owl-carousel .owl-item .read-more a {
  padding: 0 20px;
  color: #0067a7;
}

.common-home .news-row .owl-carousel .owl-item .read-more a:hover {
  color: #0067a7;
}

.common-home .news-row .owl-carousel .owl-buttons div {
  height: 44px;
  color: #0067a7 !important;
}

.common-home .about-text-row {
  margin: 0 0 12px;
}

.common-home .about-text-box {
  padding: 0 18px 18px;
  margin-top: 0;
  box-shadow: none;
  text-align: left;
}

.common-home .about-text-box .oct-carousel-header {
  margin-top: 0;
}

.common-home .about-text-box p {
  color: #252525;
}

#sstore-3-level > ul > li > a {
  font-size: 16px;
}

#sstore-3-level ul:first-child > li > a {
  padding-top: 20px;
}

#sstore-3-level > ul > li .toggle-a {
  padding: 20px 20px 35px 30px;
}

#sstore-3-level ul:first-child li.has-sub > a.toggle-a:before,
#sstore-3-level ul:first-child li.has-sub > a.toggle-a:after {
  background: #ffffff !important;
}

#sstore-3-level ul:first-child li.has-sub > a.toggle-a:before {
  top: 27px;
}

#sstore-3-level ul:first-child li.has-sub > a.toggle-a:after {
  top: 23px;
}

#sstore-3-level ul > li.has-sub > a.toggle-a:before,
#sstore-3-level ul > li.has-sub > a.toggle-a:after {
  background: #ffffff !important;
}

#sstore-3-level ul > li.has-sub.open > a.toggle-a:after,
#sstore-3-level ul > li.has-sub.open > a.toggle-a:before {
  background: #ffffff !important;
}

#sstore-3-level ul > li.has-sub li.has-sub > a.toggle-a:after,
#sstore-3-level ul > li.has-sub li.has-sub > a.toggle-a:before {
  background: #0067a7 !important;
}

#sstore-3-level ul > li.has-sub li.has-sub li.has-sub > a.toggle-a:after,
#sstore-3-level ul > li.has-sub li.has-sub li.has-sub > a.toggle-a:before {
  background: #252525 !important;
}

#sstore-3-level ul > li.has-sub li.has-sub li.has-sub > a.toggle-a:after {
  top: 13px;
}

#sstore-3-level ul > li.has-sub li.has-sub li.has-sub > a.toggle-a:before {
  top: 17px;
}

#sstore-3-level ul ul ul li a:hover {
  color: #252525;
}

h1.cat-header {
  color: #252525 !important;
}

.subcat-header {
  color: #252525 !important;
}

.row.sort-row {
  background: #f8f8f8;
}

.sort-row .input-group-addon {
  color: #252525;
}

.appearance .btn-group button,
.appearance .btn-group button.active,
.appearance .btn-group button:hover {
  background: #ffffff;
  color: #252525;
}

#input-sort,
#input-limit {
  background-image: url(../image/input-sort.png), linear-gradient(#ffffff, #ffffff 40%, #ffffff);
}

#subcats .subcat-box a span {
  color: #252525 !important;
}

#subcats .subcat-box:hover a {
  color: #252525;
  text-decoration: none;
}

#res-products .product-grid {
  min-height: 506px;
}

.product-thumb h4 a {
  color: #0067a7 !important;
}

.cat-model {
  margin: 8px 0 12px;
  color: #252525;
}

.product-thumb .rating {
  display: none;
}

.product-thumb .price .common-price {
  margin-top: 15px;
}

.product-thumb .oct-product-desc {
  color: #252525;
}

.product-grid .product-thumb .oct-additional-info {
  color: #252525;
}

.cat-desc-box a {
  text-decoration: none;
}

.product-header {
  color: #252525;
}

.product-rating-box {
  display: none;
}

.after-header-item .blue i {
  margin: 0 10px 0 0;
  color: #0067a7;
}

.after-header-item a {
  color: #252525;
}

.found-cheaper a {
  color: #0067a7;
  text-decoration: none;
}

.price-col p {
  display: none;
}

.number .btn-minus button i,
.number .btn-plus button i {
  color: #4f5f6f;
}

.product-info-li a,
.product-info-li span {
  color: #4f5f6f;
}

.product-price h2 {
  font-family: "PTSans Regular", sans-serif;
  font-size: 24px;
}

.product-tabs-row a {
  color: #0067a7;
}

.oct-carousel-header {
  color: #252525;
  text-transform: initial;
}

.oct-carousel-header:after {
  display: none;
}

.oct-carousel-row .owl-carousel .owl-item .name a {
  color: #0067a7;
}

.oct-carousel-row .owl-carousel .owl-item .name a:hover {
  text-decoration: none;
}

.oct-carousel-row .owl-carousel .owl-item .image:hover + .name a {
  text-decoration: none;
}

.oct-carousel-row .owl-carousel .owl-item .oct-button {
  padding: 10px 36px;
}

.oct-carousel-row .owl-carousel .owl-buttons div i {
  color: #0067a7;
  font-size: 24px;
}

.oct-carousel-row .owl-carousel .owl-buttons div {
  height: 44px;
  line-height: 52px;
}

.oct-bottom-cart-box .table > thead > tr > td,
.oct-bottom-cart-box .table > thead > tr > th {
  padding: 4px;
  background: #0067a7;
  color: #ffffff;
}

.oct-bottom-cart-box .table > thead > tr > td {
  padding: 4px 14px;
}

.oct-bottom-cart-box .table > tbody > tr > td {
  color: #252525;
}

.oct-bottom-cart-box .table .oct-bottom-cart-table-text {
  font-family: "PTSans Regular", sans-serif;
  color: #252525 !important;
}

.oct-bottom-cart-box .table .oct-bottom-cart-table-text:hover {
  text-decoration: none !important;
}

.input-group.btn-block {
  display: flex;
  align-items: center;
  max-width: initial !important;
}

.input-group.btn-block input[name="quantity"] {
  margin: 0;
  color: #252525;
}

.input-group.btn-block .btn-minus button i,
.input-group.btn-block .btn-plus button i {
  color: #252525;
}

.input-group.btn-block .btn-minus button,
.input-group.btn-block .btn-plus button {
  border: none;
  background: transparent;
}

.oct-bottom-cart-total-cart .total-text {
  color: #252525;
}

.oct-bottom-cart-total-cart .total-text span {
  color: #252525 !important;
}

.oct-checkout-content {
  border-left: none;
}

.oct-checkout-content .login-checkout {
  display: none;
}

.oct-checkout-content .oct-fastorder-header {
  margin: 0 0 12px;
  color: #252525;
}

.oct-checkout-content .oct-fastorder-header span {
  display: none;
}

.oct-checkout-content .fastorder-comments {
  margin: 0;
}

.oct-checkout-content .input-group .form-control[name^=quantity] {
  min-width: 66px;
}

#confirm_code {
  margin: 10px 0 0;
}

.form-control[name="comment"] {
  margin-bottom: 8px;
}

.payment-block > div {
  margin-bottom: 8px;
}

.fastorder-panel-default {
  margin-bottom: 0;
}

.oct-fastorder-payment .oct-button {
  margin: 12px 0 0;
}

.oct-fastorder-payment .buttons .pull-right {
  float: left !important;
}

.oct-fastorder-payment .buttons .pull-right > * {
  padding: 9px 18px;
  background: #0067a7 !important;
  border: none;
  outline: none;
}

.narrow-popup {
  max-width: 800px;
  margin: 20px auto;
}

#popup-cart-inner .oct-carousel-header {
  text-transform: initial;
  color: #252525;
}

#popup-cart-inner .popup-text {
  color: #252525;
}

#popup-cart-inner .popup-cart-box .item-name a {
  color: #252525 !important;
}

#popup-cart-inner .popup-cart-box .item-name a:hover {
  text-decoration: none;
  color: #252525 !important;
}

#popup-cart-inner .popup-cart-box .input-group .form-control[name^=quantity] {
  min-width: 62px;
  color: #252525 !important;
}

#popup-cart-inner .item-quantity .input-group {
  display: flex;
  align-items: center;
  max-width: initial !important;
}

#popup-cart-inner .btn-minus,
#popup-cart-inner .btn-plus,
#popup-cart-inner input.plus-minus {
  display: block;
  min-width: initial;
  max-width: initial;
}

#popup-cart-inner .btn-minus button i,
#popup-cart-inner .btn-plus button i {
  color: #252525;
}

#popup-cart-inner .btn-minus button,
#popup-cart-inner .btn-plus button {
  border: none;
  background: transparent;
}

#popup-cart-inner .fa-shopping-cart {
  display: none;
}

#popup-cart-inner .popup-total-cart .total-text {
  color: #252525;
}

#popup-cart-inner .popup-buttons-box .popup-button {
  height: auto;
  line-height: 22px;
  padding: 8px 25px 9px;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

#popup-cart-inner .oct-cart-item > div.delete-item button {
  font-size: 20px;
}

h2.popup-header {
  text-transform: initial;
  color: #252525;
}

body {
  font-family: 'PTSans Regular', sans-serif;
  color: #252525;
}

a {
  color: #0067a7;
}

a:hover {
  color: #eb1227;
}

a.list-group-item,
button.list-group-item,
input[type='text'].form-control,
select.form-control,
input[type='password'].form-control,
.filtered .link i,
.filtered .checkbox input[type=checkbox] + label,
.filtered .checkbox-inline input[type=checkbox] + label,
.filtered .radio input[type=radio] + label,
.filtered .radio-inline input[type=radio] + label,
.popup-form-box input[type='text'],
.popup-form-box input[type='tel'],
.popup-form-box input[type='email'],
#auth-popup .auth-popup-links a.reg-popup-link,
.popup-text, .popup-text a, #product .control-label,
.popup-form-box textarea {
  color: #252525;
}

#oct-bluring-box {
  background: #ffffff;
}

a.wishlist.oct-button.current-link,
a.compare.oct-button.current-link {
  display: none !important;
}

a.wishlist.oct-button,
a.compare.oct-button {
  display: none !important;
}

a.oct-button.button-wishlist,
a.oct-button.button-compare {
  display: none !important;
}

.breadcrumb i {
  font-size: 11px;
}

.breadcrumb > li > a {
  color: #252525 !important;
}

.breadcrumb > li span {
  color: #252525;
}

.content-row {
  box-shadow: none;
}

.oct-discount-item {
  background: #eb1227;
}

#back-top {
  right: 56px;
}

#uptocall-mini {
  right: 43px;
}

#uptocall-mini .uptocall-mini-phone i {
  top: 16px;
  left: 16px;
  color: #ffffff;
  font-size: 24px;
}

/* DESKTOP FIRST */
@media screen and (max-width: 1200px) {
  /* product */
  #product-info-right .product-rating-box {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  /* header */
  header {
    padding: 68px 0 13px;
  }
  #m-wishlist {
    display: none;
  }
  #m-cart {
    top: 8px;
    margin-right: 22px;
  }
  /* menu */
  #menu .oct-mm-simplecat .second-level-li > a {
    font-size: 14px;
  }
  /* common */
  .oct-product-tab ul.nav-tabs > li.active {
    background: #ececec;
  }
  .oct-product-tab ul.nav-tabs > li.active a {
    font-size: 14px;
  }
  .oct-product-tab ul.nav-tabs > li a {
    font-size: 14px;
  }
  .oct-product-tab ul.nav-tabs > li a:hover {
    font-size: 14px;
  }
  /* checkout */
  .oct-checkout-content {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  /* category */
  #res-products .product-grid {
    min-height: 370px;
  }
  .cat-model {
    font-size: 12px;
  }
  .product-thumb h4 a {
    font-size: 14px;
  }
  .product-thumb .cart a.button-cart i {
    display: inline-block;
    margin: 0 3px 0 0;
  }
  .product-thumb .cart a > span {
    display: inline-block;
  }
  /* product */
  .after-header-row > div {
    margin: 0 0 12px;
  }
  .after-header-row .fa-check-circle {
    display: inline-block;
  }
  .oct-product-stock {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  /**/
}

@media screen and (max-width: 480px) {
  /* popup cart */
  .popup-buttons-box {
    display: flex;
    flex-direction: column;
  }
  #popup-cart-inner .popup-buttons-box .popup-button {
    margin: 0 0 12px;
  }
  .oct-cart-item > div.item-quantity {
    margin-top: 33px;
    margin-left: 138px;
  }
  /* footer */
  #newsletter::placeholder {
    font-size: 10px;
  }
  footer .third-row .f-acc-toggle:before,
  footer .third-row .f-acc-toggle:after {
    background: #252525;
  }
}

@media screen and (max-width: 479px) {
  .oct-cart-item > div.item-price {
    top: -31px;
    left: 36px;
  }
}

@media screen and (max-width: 360px) {
  /**/
}

@media screen and (max-width: 320px) {
  /* category */
  .product-thumb .cart a.button-cart {
    padding: 12px 7px;
    font-size: 13px;
  }
  /* popup cart */
  .oct-cart-item > div.item-price {
    top: -24px;
    margin-top: 9px;
    margin-left: 127px;
  }
}

/* MOBILE FIRST */
@media screen and (min-width: 992px) {
  /**/
}

@media screen and (min-width: 993px) {
  /**/
}

@media screen and (min-width: 1024px) {
  /* header */
  .product-grid .product-thumb:hover h4 a {
    text-decoration: none;
  }
  /* common */
  .common-home .oct-product-tab ul.nav-tabs {
    display: flex;
    border: none;
  }
  .common-home .oct-product-tab ul.nav-tabs:before, .common-home .oct-product-tab ul.nav-tabs:after {
    display: none;
  }
  .common-home .oct-product-tab ul.nav-tabs > li {
    width: 25%;
    margin: 0 25px 0 0;
    border: 1px solid #ececec;
  }
  .common-home .oct-product-tab ul.nav-tabs > li:first-child {
    border-left: 1px solid #ececec;
  }
  .common-home .oct-product-tab ul.nav-tabs > li:last-child {
    margin: 0;
  }
  .common-home .oct-product-tab .owl-item:hover .oct-product-stock {
    display: none;
  }
  .common-home .oct-product-tab .cat-box-effect {
    height: 40px;
    overflow: hidden;
  }
  .common-home .oct-product-tab .owl-item .cat-box-effect-inner {
    height: 80px;
    overflow: hidden;
    transition: transform .3s ease;
    transform: rotate(0);
  }
  .common-home .oct-product-tab .owl-item:hover .cat-box-effect-inner {
    transform: translateY(-40px);
  }
}

@media screen and (min-width: 1200px) {
  /**/
}
